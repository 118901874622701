$(function () {

    var emailRegex = new RegExp(/^\s*([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+\s*$/);
    var majorUnitAmountRegex = new RegExp(/^(\+|-)?([0-9]{1,15})?(\.[0-9]{1,2})?$/);
    var majorUnitAmountWholeNumberRegex = new RegExp(/^(\+|-)?([0-9]{1,9})$/);
    var dateRegex = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
    var datePattern = "yyyy-mm-dd";
    var transactionAmountValueMax = 10000000000;
    var maximumMinorUnitAmount = 99999999999999999n;

    var andedSequence = function () {
        var fns = arguments;
        return function (value, element, param) {
            var result = true;
            for (var i = 0; i < fns.length; i++) {
                result = result && fns[i].call(this, value, element, param);
            }
            return result;
        };
    };

    var equalto = function (equalValue) {
        var equalToValue = equalValue;
        return function (value) {
            return value == equalToValue;
        };
    };

    var lessThan = function (maxValue) {
        var maximumValue = maxValue;
        return function (value) {
            return value < maximumValue;
        };
    };

    var lessThanForMUA = function (maxValue) {
        var maximumValue = maxValue;
        return function (value) {
            return BigInt(value.replace(/\./g, '')) < maximumValue;
        };
    };

    var lesserThanOrEqualToForMUA = function (maxValue) {
        var maximumValue = maxValue;
        return function (value) {
            return BigInt(value.replace(/\./g, '')) <= maximumValue;
        };
    };

    var greaterThan = function (minValue) {
        var minimumValue = minValue;
        return function (value) {
            return value > minimumValue;
        };
    };

    var greaterThanOrEqualTo = function (minValue) {
        var minimumValue = minValue;
        return function (value) {
            return value >= minimumValue;
        };
    };

    var lesserThanOrEqualTo = function (minValue) {
        var minimumValue = minValue;
        return function (value) {
            return value <= minimumValue;
        };
    };

    var format = function (regEx) {
        var regularExpression = regEx;
        return function (value,element) {
            return this.optional(element) || regularExpression.test(value);
        };
    };

    var formatInputValue = function (value) {
        if(new RegExp(/\.\d{2}$/).test(value)) {
            return BigInt(value.replace(/\./g, ''));
        } else if(new RegExp(/\.\d{1}$/).test(value)) {
            return BigInt(value.replace(/\./g, '').concat('0'));
        } else if(value.indexOf('.') === -1) {
            return BigInt(value.concat('00'));
        } else {
            return BigInt(value);
        }
    };

    function addValidations(name, message, validations) {
        $.validator.addMethod(name, function (value, element, params) {
            return validations.apply(this, [value, element, params]);
        }, message);
    }

    var genericAmountMaximum = function(value, element, param) {
        var maximumValue = parseFormattedAmount(param, element);
        return value <= maximumValue;
    };

    var genericAmountMinimum = function(value, element, param) {
        var minimumValue = parseFormattedAmount(param, element);
        return value >= minimumValue;
    };

    function parseFormattedAmount(param, element) {
        var separator = $(element).data('amount-separator');
        var delimiter = $(element).data('amount-delimiter');

        if (typeof param === "number") {
            return param;
        } else {
            var amount = param.replace(new RegExp(delimiter, 'g'), '');

            return parseFloat(amount.replace(separator, '.'));
        }
    }

    addValidations("format", $.validator.messages.formatMismatch);
    addValidations("email", $.validator.messages.email, format(emailRegex));
    addValidations("countryCode", $.validator.messages.phoneNumber, format(/^[0-9]{1,4}$/));
    addValidations("consumerNameFormat", $.validator.messages.consumerNameFormat, format(/^[^0-9\@\(\)\_]+$/));
    addValidations("cityFormat", $.validator.messages.cityFormat, format(/^[^0-9\@\(\)\_]+$/));
    addValidations("addressFormat", $.validator.messages.addressFormat, format(/^[^\@\(\)\_]+$/));
    addValidations("atLeastOneAlphabetFormat", $.validator.messages.atLeastOneAlphabetFormat, format(/[^\d\s]+/));
    addValidations("alphanumeric", $.validator.messages.alphanumeric, format(/^([0-9a-zA-Z\s])*$/));
    addValidations("digits", $.validator.messages.digits, format(/^[0-9]+(,[0-9]+)*$/));
    addValidations("passwordPolicy", $.validator.messages.password, format(/^(?!.*[\s])(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,.\/?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,30}$/));
    addValidations("phoneNumber", $.validator.messages.phoneNumber, format(/^\+?[0-9]{7,15}$/));
    addValidations("bankCardNumber", $.validator.messages.bankCardNumber, format(/^[0-9]{16,19}$/));
    addValidations("beneficiaryIdNumber", $.validator.messages.beneficiaryIdNumber, format(/^[a-zA-Z]{1}[0-9]{9}$/));
    addValidations("issuerIdentificationNumber", $.validator.messages.issuerIdentificationNumber, format(/^[0-9]{6}$/));
    addValidations("cardHolderName", $.validator.messages.cardHolderName, format(/^[A-Z.\-' ]+ [A-Z.\-']+$/));
    addValidations("bankCardTailNumber", $.validator.messages.bankCardTailNumber, format(/^[0-9]{4}$/));
    addValidations("statusUpdateNote", $.validator.messages.statusUpdateNote, format(/^.{1,255}$/));

    addValidations("percentageWithoutDecimal", $.validator.messages.percentageWithoutDecimal,
        andedSequence(greaterThanOrEqualTo(0), lesserThanOrEqualTo(100), format(majorUnitAmountWholeNumberRegex)));
        addValidations("transactionAmount", $.validator.messages.transactionAmount,
        andedSequence(greaterThan(0), lessThan(transactionAmountValueMax), format(majorUnitAmountRegex)));
    addValidations("transactionAmountWholeNumber", $.validator.messages.transactionAmount,
        andedSequence(greaterThan(0), lessThan(transactionAmountValueMax), format(majorUnitAmountWholeNumberRegex)));
    addValidations("rangeAmountWholeNumber", $.validator.messages.limitedRangeAmount,
        andedSequence(greaterThan(0), lessThanForMUA(maximumMinorUnitAmount), format(majorUnitAmountRegex)));
    addValidations("fixedFeeAmount", $.validator.messages.fixedFeeAmount,
        andedSequence(greaterThanOrEqualTo(0), lesserThanOrEqualToForMUA(maximumMinorUnitAmount), format(majorUnitAmountRegex)));
    addValidations("percentage", $.validator.messages.percentage,
        andedSequence(greaterThanOrEqualTo(0), lesserThanOrEqualTo(100), format(majorUnitAmountRegex)));
    addValidations("majorUnitAmount", $.validator.messages.majorUnitAmount,
        andedSequence(greaterThanOrEqualTo(0), format(majorUnitAmountRegex)));
    addValidations("transactionAdjustmentAmount", $.validator.messages.majorUnitAmount, andedSequence(function (value) {
        return value > 0 || value < 0;
    },format(majorUnitAmountRegex)));

    addValidations("termsAndCondition", $.validator.messages.termsAndCondition, equalto(1));

    addValidations("privacyPolicy", $.validator.messages.privacyPolicy, equalto(1));

    $.validator.addMethod("amountMaximum", genericAmountMaximum, $.validator.messages.amountMaximum);

    $.validator.addMethod("withdrawalAmountMinimum",
        genericAmountMinimum, $.validator.messages.withdrawalAmountMinimum
    );

    $.validator.addMethod("withdrawalAmountMaximum",
        genericAmountMaximum, $.validator.messages.withdrawalAmountMaximum
    );

    $.validator.addMethod("bankPayoutAmountMinimum",
        genericAmountMinimum, $.validator.messages.bankPayoutAmountMinimum
    );

    $.validator.addMethod("bankPayoutAmountMaximum",
        genericAmountMaximum, $.validator.messages.bankPayoutAmountMaximum
    );

    $.validator.addMethod("transferAmountMinimum",
        genericAmountMinimum, $.validator.messages.transferAmountMinimum
    );

    $.validator.addMethod("transferAmountMaximum",
        genericAmountMaximum, $.validator.messages.transferAmountMaximum
    );

    $.validator.addMethod("prepaidCardLoadAmountMinimum",
        genericAmountMinimum, $.validator.messages.prepaidCardLoadAmountMinimum
    );

    $.validator.addMethod("prepaidCardLoadAmountMaximum",
        genericAmountMaximum, $.validator.messages.prepaidCardLoadAmountMaximum
    );

    $.validator.addMethod("sortCode", function(value, element){
        switch($(element).attr('target'))
        {
            case 'Canada':
                return validateCanadaSortCode(value);
            case 'United States':
                return validateUnitedStatesSortCode(value);
            default:
                return true;
        }
    }, $.validator.messages.sortCode);

    $.validator.addMethod("bsbNumber", function(value, element){
        var regex = /^(\d{6}|[0-9]{3}-[0-9]{3})$/;

        return this.optional(element) || regex.test(value);
    }, $.validator.messages.bsbNumber);

    $.validator.addMethod("bankNumber", function(value, _){
        var regex = /^([0-9]{2}|[0-9]{4,6}|[0-9]{8})$/;

        return regex.test(value);
    }, $.validator.messages.bankNumber);

    $.validator.addMethod("validdate", function (value, element) {
        var day, month, year;
        if ($(element).is("select")) {
            var dateElements = $(element).siblings("select").addBack();
            day = $(dateElements[0]).val();
            month = $(dateElements[1]).val();
            year = $(dateElements[2]).val();
            if (_.isEmpty(day) || _.isEmpty(month) || _.isEmpty(year)) { // skip until all dropdowns selected
                return true;
            }
        } else {
            var dateValue = $(element).val();

            if (dateValue === '') {
                return true;
            }

            if(!dateRegex.test(dateValue)) {
                return false;
            }
            day = dateValue.substr(datePattern.indexOf("dd"), 2);
            month = dateValue.substr(datePattern.indexOf("mm"), 2);
            year = dateValue.substr(datePattern.indexOf("yyyy"), 4);
        }
        month = (month - 1).toString();
        var date = new Date(year, month, day);
        return (date.getDate() == day) && (date.getMonth() == month) && (date.getFullYear() == year);
    }, $.validator.messages.invalidDate);

    $.validator.addMethod("validdaterange", function (value, element) {
        function dateWithoutTime(refDate) {
            return new Date(refDate.getFullYear(), refDate.getMonth(), refDate.getDate());
        }
        var startDateElement = $(element).parents("form").find(".startDate");
        var endDateElement = $(element).parents("form").find(".endDate");
        if (!dateRegex.test(startDateElement.val()) || !dateRegex.test(endDateElement.val())) {
            // skip until valid date formats specified for both
            return true;
        }

        var startDate = dateWithoutTime(new Date(startDateElement.val().replace(/-/g, '/'))),
            endDate = dateWithoutTime(new Date(endDateElement.val().replace(/-/g, '/'))),
            currentDate = dateWithoutTime(new Date()),
            sevenYearsAgo = new Date(currentDate.getFullYear() - 7, currentDate.getMonth(), currentDate.getDate()),
            msDay = 60*60*24*1000;

        return (startDate <= endDate) && (endDate <= currentDate) && (startDate >= sevenYearsAgo) &&
            (((endDate - startDate) / msDay) <= 90);
    }, $.validator.messages.invalidDateRange);

    $.validator.addMethod("optionalEmployee", function (value, element) {
        var domElement = $(element);
        var template = domElement.closest('.js-dynamicEmployee');

        var firstNameValue = template.find("input[name*='first_name']").val();
        var lastNameValue = template.find("input[name*='last_name']").val();
        var emailValue = template.find("input[name*='email']").val();

        if (firstNameValue || lastNameValue || emailValue) {
            return firstNameValue !== "" && lastNameValue !== "" && emailValue !== "";
        }
        return true;
    }, $.validator.messages.optionalEmployee);

    $.validator.addMethod("consumerQueryByEmails", function (value) {
        var emails = $.trim(value).split(/[\s,\n,;]+/);

        for (var index = 0; index < emails.length; index++) {
            if (!emailRegex.test(emails[index])) {
                return false;
            }
        }
        return true;
    }, $.validator.messages.invalidEmailFormatInQuery);

    $.validator.addMethod('toFeeRange', function (value, element) {
        var fromFeeRange = formatInputValue($(element).parents('.feesC2B-rule').find('.from').val());
        var toFeeRange = formatInputValue(value);

        return fromFeeRange < toFeeRange;
    }, $.validator.messages.toFeeRange);

    $.validator.addMethod("validExpirationDate", function (value, element) {
        var dateElements = $(element).siblings("select").addBack();
        var month = $(dateElements[0]).val();
        var year = $(dateElements[1]).val();

        var currentDate = new Date();
        return year > currentDate.getFullYear() ||
            (year == currentDate.getFullYear()) && month - 1 >= currentDate.getMonth();
    }, $.validator.messages.invalidExpirationDate);

    $.validator.addMethod("checkedRadioButton", function (value, element) {
        return !!$("input[name='" + element.name + "']:radio:checked").length;
    }, $.validator.messages.radioButtonChecked);

    $.validator.addMethod('validCardBin', function(value) {
        var url = '/consumers/deposits/check_for_supported_card_brand?bin='+value,
        success = false;

        $.ajax({
          type: 'GET',
          url: url,
          dataType: 'json',
          async: false,
          success: function(response) {
              success = response.success;
          }
        });

        return success;
    }, $.validator.messages.validCardBin);

    $.validator.addMethod("validIdentifier", function (value, element, params) {
        switch (params.country) {
            case 'ar':
                return /^\d{7,9}|\d{11}$/.test(value);
            case 'br':
                return /^\d{11,14}$/.test(value);
            case 'co':
                return /^\d{6,10}$/.test(value);
            case 'cl':
                return /^\d{8,9}$/.test(value);
            case 'in':
                return /^[A-Z]{5}\d{4}[A-Z0-9]{1}$/.test(value);
            case 'mx':
                return /^\d{10,18}$/.test(value);
            case 'pe':
                return /^\d{8,9}$/.test(value);
            default:
                return true;
        }
    });

    var validateUnitedStatesSortCode = function(value) {
        var routing = value;
        if (routing.length !== 9) {
            return false;
        }
        if ( !$.isNumeric( routing ) ) {
            return false;
        }
        if ( routing[0] == '5' ) {
            return false;
        }
        var checksumTotal = (7 * (parseInt(routing.charAt(0),10) + parseInt(routing.charAt(3),10) + parseInt(routing.charAt(6),10))) +
                            (3 * (parseInt(routing.charAt(1),10) + parseInt(routing.charAt(4),10) + parseInt(routing.charAt(7),10))) +
                            (9 * (parseInt(routing.charAt(2),10) + parseInt(routing.charAt(5),10) + parseInt(routing.charAt(8),10)));

        var checksumMod = checksumTotal % 10;
        if (checksumMod !== 0) {
            return false;
        } else {
            return true;
        }
    };

    var validateCanadaSortCode = function(value) {
        return ( /\d{5}-\d{3}$/.test(value));
    };
});
